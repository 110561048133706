import React from "react";
import styles from "./BottomSheet.module.scss";
import DeviceContext from "src/contexts/DeviceContext";
import debug from "src/utils/debugUtils";

export enum BottomSheetState {
    FULLSCREEN = "fullscreen",
    DISMISSED = "dismissed",
}

type Props = {
    state: BottomSheetState;
    afterDismiss?: VoidFunction;
    afterOpen?: VoidFunction;
    onDragStart?: VoidFunction;
    onDragEnd?: VoidFunction;
    children?: React.ReactNode;
    limitWidth?: boolean;
};

type State = {
    bottomSheetState: BottomSheetState;
    hasOpened: boolean;
};

class BottomSheet extends React.PureComponent<Props, State> {
    static contextType = DeviceContext;
    declare context: React.ContextType<typeof DeviceContext>;

    DRAG_START_THRESHOLD_PX = 10;
    DRAG_STATE_CHANGE_DISTANCE_THRESHOLD_PERCENT = 0.15;
    DRAG_STATE_CHANGE_VELOCITY_THRESHOLD = 1.2;
    VIEWPORT_HEIGHT = window.innerHeight;
    STATE_CHANGE_TRANSITION_DURATION_MS = Number(styles.bottomSheetStateTransitionDuration);

    isTouching = false;
    isDragging = false;
    touchId: number | undefined;
    touchStartX: number | undefined;
    touchStartY: number | undefined;
    bottomSheet: React.RefObject<HTMLDivElement>;
    lastTranslateY: number | undefined;
    isMovingDown = false;
    animationFrame: number | undefined;
    gestureStartY: number | undefined;
    gestureStartTimestamp: number | undefined;
    stateChangeAnimationDuration: number | undefined;
    dismissTimeout: ReturnType<typeof setTimeout> | undefined;
    hasDismissed = false;
    isSmoothBottomSheetDraggingEnabled = false;

    constructor(props: Props) {
        super(props);

        this.state = {
            // Delay opening transition even if initial state from props is "fullscreen"
            bottomSheetState: BottomSheetState.DISMISSED,
            hasOpened: false
        };

        this.bottomSheet = React.createRef<HTMLDivElement>();
        this.isSmoothBottomSheetDraggingEnabled = debug.get("smoothBottomSheetDragging");
    }

    componentDidMount(): void {
        // Attach this listener manually so that we can set passive to false and use .preventDefault()
        // to prevent horizontal scroll events from firing during vertical dragging movements
        this.bottomSheet.current?.addEventListener("touchmove", this.handleTouchMove, {
            passive: false,
        });

        // Delay opening transition even if initial state from props is "fullscreen"
        // so that we can automatically play the opening animation
        if (this.state.bottomSheetState !== this.props.state) {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    this.setState({ bottomSheetState: this.props.state });
                });
            });
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.state !== this.props.state && this.state.bottomSheetState !== this.props.state) {
            this.setState({ bottomSheetState: this.props.state });
        }

        if (prevState.bottomSheetState === BottomSheetState.FULLSCREEN && this.state.bottomSheetState === BottomSheetState.DISMISSED) {
            const dismissWaitMs = this.stateChangeAnimationDuration ?? this.STATE_CHANGE_TRANSITION_DURATION_MS;
            this.dismissTimeout = setTimeout(this.internalBottomSheetDismissedCallback, dismissWaitMs);
        }
    }

    componentWillUnmount(): void {
        this.bottomSheet.current?.removeEventListener("touchmove", this.handleTouchMove);
    }

    endDragging = () => {
        this.isTouching = false;
        this.isDragging = false;
        this.isMovingDown = false;
        this.touchId = undefined;
        this.touchStartX = undefined;
        this.touchStartY = undefined;
        this.lastTranslateY = undefined;
        this.gestureStartY = undefined;
        this.gestureStartTimestamp = undefined;

        if (this.animationFrame) {
            cancelAnimationFrame(this.animationFrame);
            this.animationFrame = undefined;
        }

        // Update element styles inside requestAnimationFrame to ensure they happen AFTER last touchmove change,
        // otherwise we can get stuck in a partially open state with a leftover inline `transform` value
        requestAnimationFrame(() => {
            if (this.bottomSheet.current) {
                this.bottomSheet.current.style.transform = "";
            }
        });

        setTimeout(() => {
            if (this.bottomSheet.current) {
                this.bottomSheet.current.style.transition = "";
            }
            this.props.onDragEnd?.();
        }, this.stateChangeAnimationDuration ?? 0);

        this.stateChangeAnimationDuration = undefined;
    };

    handleTransitionEnd: React.TransitionEventHandler<HTMLDivElement> = (event) => {
        if (this.isSmoothBottomSheetDraggingEnabled && this.isDragging) {
            return;
        }

        // We only want to listen to bottom sheet expand/collapse animations completing
        if (event.target !== this.bottomSheet.current) {
            return;
        }

        if (this.state.bottomSheetState === BottomSheetState.FULLSCREEN) {
            if (!this.state.hasOpened) {
                this.setState({ hasOpened: true })
                this.props.afterOpen?.();
            }
        } else {
            this.internalBottomSheetDismissedCallback();
        }
    };

    handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
        if (event.touches.length === 1) {
            this.isTouching = true;
            const touch = event.touches[0];
            this.touchId = touch.identifier;
            this.touchStartX = touch.clientX;
            this.touchStartY = touch.clientY;
        }
    };

    handleTouchMove = (event: TouchEvent) => {
        if (this.animationFrame) {
            // A position update is already scheduled for this frame, skip unnecessary work
            return;
        }

        if (!this.isTouching) {
            // This is an invalid movement (e.g. multiple touches simultaneously) - ignore it
            return;
        }

        const touch = event.changedTouches[0];
        if (touch.identifier !== this.touchId) {
            // This movement is for a different finger - ignore it
            return;
        }

        if (this.touchStartX === undefined || this.touchStartY === undefined) {
            // Touch start positions have not yet been recorded, cannot compute a diff - ignore it
            return;
        }

        if (!this.bottomSheet.current) {
            // Bottom sheet ref could not be found, no touch actions possible - ignore it
            return;
        }

        const dx = Math.abs(touch.clientX - this.touchStartX);
        const dy = touch.clientY - this.touchStartY;
        const isNowDragging =
            dy > dx &&
            dy >= this.DRAG_START_THRESHOLD_PX &&
            // Allow up to a 45 degree angle away from vertical when dragging up/down
            dx < this.DRAG_START_THRESHOLD_PX;

        if (!this.isDragging) {
            if (dx >= this.DRAG_START_THRESHOLD_PX) {
                // This is a horizontal scroll, not a touch gesture we want to handle at any point
                this.endDragging();
                return;
            }

            if (!isNowDragging) {
                // We still haven't met the requirements to recognize a drag - ignore it
                return;
            }

            this.isDragging = true;

            // Dragging has started, reduce animation duration temporarily
            if (this.isSmoothBottomSheetDraggingEnabled) {
                // Apply roughly 2 frames of smoothing (1000ms / 60 * 2)
                this.bottomSheet.current.style.transition = "transform 33ms linear";
            } else {
                this.bottomSheet.current.style.transition = "none";
            }

            this.props.onDragStart?.();
        }

        if (event.cancelable) {
            // This is also a scroll event, cancel to only allow vertical movements
            event.preventDefault();
        }

        const dragDownDistance = touch.clientY - this.touchStartY;
        const translateY = Math.max(0, dragDownDistance); 

        this.animationFrame = requestAnimationFrame(() => {
            if (this.bottomSheet?.current) {
                this.bottomSheet.current.style.transform = `translateY(${translateY}px)`;
            }

            this.animationFrame = undefined;
        });

        if (this.lastTranslateY !== undefined) {
            const isNowMovingDown = this.lastTranslateY < translateY;

            if (this.isMovingDown !== isNowMovingDown) {
                // Dragging gesture direction changed, reset gesture start position + timestamp
                this.gestureStartY = touch.clientY;
                this.gestureStartTimestamp = Date.now();
                this.isMovingDown = isNowMovingDown;
            }
        } else {
            // New dragging gesture, set initial gesture start position + timestamp
            this.gestureStartY = touch.clientY;
            this.gestureStartTimestamp = Date.now();
        }
        this.lastTranslateY = translateY;
    };

    handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
        const touch = event.changedTouches[0];

        if (touch.identifier !== this.touchId) {
            // This is an ignored touch event being released (e.g. second finger touching) - ignore it
            return;
        }

        if (!this.isDragging || this.touchStartY === undefined) {
            // Touch start position has not yet been recorded, cannot compute a diff - ignore it
            this.endDragging();
            return;
        }

        // Determine whether BottomSheet drag down distance is sufficient to dismiss
        const dragDownDistance = touch.clientY - this.touchStartY;
        const dragDownRatio = Math.abs(dragDownDistance / this.VIEWPORT_HEIGHT);
        const reachedDismissDistanceThresdhold = this.isMovingDown 
            && dragDownRatio > this.DRAG_STATE_CHANGE_DISTANCE_THRESHOLD_PERCENT;

        // Determine whether drag gesture velocity is sufficient to change our decision to dismiss
        let reachedDismissVelocityThreshold = false;
        if (!reachedDismissDistanceThresdhold && this.gestureStartY !== undefined && this.gestureStartTimestamp) {
            const gestureDistanceY = touch.clientY - this.gestureStartY;
            const gestureTimeElapsed = Date.now() - this.gestureStartTimestamp;
            const gestureVelocity = gestureDistanceY / gestureTimeElapsed;
            
            reachedDismissVelocityThreshold = gestureVelocity > this.DRAG_STATE_CHANGE_VELOCITY_THRESHOLD;
        }

        // Calculate animation duration based on drag distance
        const reachedDismissThreshold = reachedDismissDistanceThresdhold || reachedDismissVelocityThreshold;
        const distanceToCover = reachedDismissThreshold
            ? this.VIEWPORT_HEIGHT - dragDownDistance 
            : dragDownDistance;
        this.stateChangeAnimationDuration = distanceToCover / this.VIEWPORT_HEIGHT * this.STATE_CHANGE_TRANSITION_DURATION_MS;

        if (this.bottomSheet.current) {
            this.bottomSheet.current.style.transition = `transform ${this.stateChangeAnimationDuration}ms ease-out`;
        }

        if (reachedDismissThreshold) {
            this.setState({ bottomSheetState: BottomSheetState.DISMISSED });
        }

        this.endDragging();
    };

    handleTouchCancel = () => {
        this.endDragging();
    };

    // Called from handleTransitionEnd (which fires when the bottom sheet animation finishes) and/or
    // from a setTimeout triggered in dismiss (which is what starts the bottom sheet dismiss animation)
    internalBottomSheetDismissedCallback = () => {
        clearTimeout(this.dismissTimeout);
        if (!this.hasDismissed) {
            this.hasDismissed = true;
            this.props.afterDismiss?.();
        }
    }

    dismiss = () => this.setState({ bottomSheetState: BottomSheetState.DISMISSED });

    render() {
        return (
            <div className={`${styles.bottomSheetContainer} ${styles[this.state.bottomSheetState]} ${this.state.hasOpened ? "" : styles.initialOpen}`}>
                <div className={styles.background} onClick={this.dismiss} />
                <div
                    className={`${styles.bottomSheet} ${this.context.device.isIOS ? styles.iOS : ""}`}
                    onTouchStart={this.handleTouchStart}
                    onTouchEnd={this.handleTouchEnd}
                    onTouchCancel={this.handleTouchCancel}
                    onScroll={this.handleTouchCancel}
                    onTransitionEnd={this.handleTransitionEnd}
                    ref={this.bottomSheet}
                >
                    <div className={styles.bottomSheetSizer}>
                        <div className={styles.bottomSheetFiller} onClick={this.dismiss} />
                        { this.props.limitWidth === true
                            ? (
                                <div className={styles.bottomSheetWidthLimiter}>
                                    <div className={styles.widthSpacer} onClick={this.dismiss} />
                                    <div className={styles.widthContent}>
                                        {this.props.children}
                                    </div>
                                    <div className={styles.widthSpacer} onClick={this.dismiss} />
                                </div>
                            )
                            : (this.props.children)
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default BottomSheet;
