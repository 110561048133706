import React, { useCallback, useContext, useRef } from "react";
import { CONTENT_TYPE } from "src/utils/asinUtils";
import { BookState, NativeAPI } from "src/utils/deviceUtils";
import Button from "../Button/Button";
import BorrowManagerContext, { FailableResult, onSuccess } from "src/contexts/BorrowManagerContext";
import debug from "src/utils/debugUtils";
import TranslationsContext from "src/contexts/TranslationsContext";
import Spinner from "../Spinner/Spinner";
import styles from "./BookBuyButton.module.scss";
import { newMetricsWithContext } from "src/utils/metricsUtils";
import { hasAudio } from "src/utils/programLogoUtils";
import focusHelper from "src/utils/focusHelper";
import DeviceContext from "src/contexts/DeviceContext";
import { Theme } from "src/utils/themeUtils";

type PropTypes = {
    item: QuickViewAsinMetadata;
    coverImageRef?: React.RefObject<HTMLDivElement>;
    isMiniHeader?: boolean;
    updateDownloadState: (contentType: CONTENT_TYPE, shouldRetryOnUnknown?: boolean) => void;
    redeemOfferByActionType: (actionType: string, callback: VoidFunction) => Promise<FailableResult>;
    hasFullBook: boolean;
    showPurchaseFlowCx: boolean;
    canAddToLibraryForFree: boolean;
    bookStatus: BookStatus;
    sampleOwned: boolean;
    bookActionInProgress: boolean;
};

type ButtonBehavior = {
    label: string;
    labelColor: string;
    backgroundColor: string;
    showSpinner?: boolean;
    action: VoidFunction;
};

const noOp = () => { /* NoOp */ };

const BookBuyButton: React.FC<PropTypes> = ({ item, coverImageRef, isMiniHeader, bookStatus, sampleOwned, bookActionInProgress, hasFullBook, showPurchaseFlowCx, canAddToLibraryForFree, updateDownloadState, redeemOfferByActionType }: PropTypes) => {
    const context = useContext(DeviceContext);
    const translations = useContext(TranslationsContext);
    const borrowManagerContext = useContext(BorrowManagerContext);
    const metrics = newMetricsWithContext(isMiniHeader ? "BookBuyButton.miniHeader" : "BookBuyButton", item.asin);
    // TODO: This should come from the CallToAction resource in AAPI instead of the sticker code
    const withAudio = !isMiniHeader && hasAudio(item.kindleProgram);
    const a11yTarget = useRef<HTMLButtonElement>(null);
    const isOpening = borrowManagerContext.isOpening(item.asin);

    const requestA11yFocus = useCallback(() => {
        focusHelper.requestFocus(a11yTarget.current);
    }, [a11yTarget]);

    const isDarkThem = context.theme === Theme.DARK;

    // TODO: This is not my favorite
    const primaryCtaButtonTextColor = showPurchaseFlowCx === false
        ? styles.buttonTextColorCTA
        : isDarkThem
            ? styles.buttonTextColorDark
            : styles.buttonTextColorLight;

    const primaryCtaButtonBackgroundColor = showPurchaseFlowCx === false
        ? styles.buttonBackgroundColorCTA
        : isDarkThem
            ? styles.buttonBackgroundColorDark
            : styles.buttonBackgroundColorLight;

    const getCoverImagePosition = () => {
        const coverImageRect = coverImageRef?.current?.getBoundingClientRect();
        if (coverImageRect) {
            return {
                x: coverImageRect.x,
                y: coverImageRect.y,
                width: coverImageRect.width,
                height: coverImageRect.height,
            };
        }
    };

    const openBook = () => {
        const contentType = item.isMagazine
            ? CONTENT_TYPE.MAGZ
            : hasFullBook
                ? CONTENT_TYPE.EBOK
                : CONTENT_TYPE.EBSP;
        metrics.recordBehavioralMetric(`ReadNow.${contentType}.click`, 1);
        if (contentType === CONTENT_TYPE.EBSP) {
            updateDownloadState(contentType);
        }
        borrowManagerContext.openBook(item.asin, item.title, item.authors, contentType, getCoverImagePosition());
    };

    const readNowBehavior: ButtonBehavior = {
        label: translations.getText("read-now"),
        labelColor: styles.buttonTextColorReadNow,
        backgroundColor: styles.buttonBackgroundColorReadNow,
        showSpinner: isOpening,
        action: openBook,
    };

    const readSampleBehavior: ButtonBehavior = {
        label: translations.getText("read-sample"),
        labelColor: styles.buttonTextColorReadNow,
        backgroundColor: styles.buttonBackgroundColorReadNow,
        showSpinner: isOpening,
        action: openBook,
    };

    const nextVellaEpisode = item.vellaData?.nextEpisode;
    const readNowVellaBehavior: ButtonBehavior = {
        label: translations.formatText("vella-read-episode-number", {episodeNumber: nextVellaEpisode?.episodeNumber || 0 + 1}),
        labelColor: styles.buttonTextColorReadNow,
        backgroundColor: styles.buttonBackgroundColorReadNow,
        action: () => borrowManagerContext.openVellaEpisode(nextVellaEpisode),
    };

    const readForFreeBehavior: ButtonBehavior = {
        label: withAudio
            ? translations.getText("read-and-listen-for-free")
            : translations.getText("read-for-free"),
        labelColor: primaryCtaButtonTextColor,
        backgroundColor: primaryCtaButtonBackgroundColor,
        action: () => {
            metrics.recordBehavioralMetric("ReadForFree.click", 1);
            redeemOfferByActionType("Borrow", requestA11yFocus);
        },
    };

    const actionInProgressBehavior: ButtonBehavior = {
        label: translations.getText("processing"),
        showSpinner: true,
        labelColor: primaryCtaButtonTextColor,
        backgroundColor: primaryCtaButtonBackgroundColor,
        action: noOp,
    };

    const addToLibraryForFreeBehavior: ButtonBehavior = {
        label: translations.getText("add-to-your-library-for-free"),
        labelColor: styles.buttonTextColorCTA,
        backgroundColor: styles.buttonBackgroundColorCTA,
        action: () => {
            metrics.recordBehavioralMetric("AddToLibraryForFree.click", 1);
            redeemOfferByActionType("Buy", requestA11yFocus);
        },
    };

    const downloadInProgressBehavior: ButtonBehavior = {
        label: translations.formatText("download-progress", {percentage: Math.ceil(bookStatus.downloadProgress * 100)}),
        labelColor: primaryCtaButtonTextColor,
        backgroundColor: primaryCtaButtonBackgroundColor,
        action: noOp,
    };

    const downloadAsin = (type: CONTENT_TYPE) => {
        debug.log(`Downloading: ${item.asin} ${type} ${item.title}`);
        NativeAPI.downloadBook(item.asin, type, item.title)
            ?.catch(debug.error)
            .finally(() => updateDownloadState(type, true));
    };

    const downloadSampleBehavior: ButtonBehavior = {
        label: translations.getText("download-sample"),
        labelColor: primaryCtaButtonTextColor,
        backgroundColor: primaryCtaButtonBackgroundColor,
        action: () => {
            metrics.recordBehavioralMetric("DownloadSample.click", 1);
            redeemOfferByActionType("Sample", requestA11yFocus).then(onSuccess(() => downloadAsin(CONTENT_TYPE.EBSP)));
        },
    };

    const getButtonBehavior = (): ButtonBehavior | null => {
        if (item.isShortStory) {
            if (nextVellaEpisode?.available) {
                return readNowVellaBehavior;
            }
            return null;
        }
        if (hasFullBook) {
            return readNowBehavior;
        }
        if (item.canBorrow) {
            return bookActionInProgress ? actionInProgressBehavior : readForFreeBehavior;
        }
        if (canAddToLibraryForFree) {
            return bookActionInProgress ? actionInProgressBehavior : addToLibraryForFreeBehavior;
        }
        if (sampleOwned) {
            return readSampleBehavior;
        }
        if (!item.hasSample) {
            return null;
        }
        if (bookActionInProgress) {
            return downloadInProgressBehavior;
        }
        switch (bookStatus.bookState) {
            case BookState.AZPluginBookStateInLibraryDownloading:
                return downloadInProgressBehavior;
            case BookState.AZPluginBookStateInLibraryOnDevice:
                return readSampleBehavior;
            default:
                return downloadSampleBehavior;
        }
    };

    const buttonBehavior = getButtonBehavior();

    if (!buttonBehavior) {
        return null;
    }

    return (
        <Button
            onClick={buttonBehavior.action}
            showProgressBar={bookStatus.bookState === BookState.AZPluginBookStateInLibraryDownloading}
            progressPercentage={bookStatus.downloadProgress * 100}
            backgroundColor={buttonBehavior.backgroundColor}
            ref={a11yTarget}
        >
            { buttonBehavior.showSpinner === true
            ? (<span> <Spinner size="min(1rem, 28px)" /> </span>)
            : (<span style={{ color: buttonBehavior.labelColor }}>{buttonBehavior.label}</span>)
            }
        </Button>
    );
};

export default BookBuyButton;
