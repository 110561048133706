import React, { useContext, useEffect, useState } from "react";
import styles from "./BuyBox.module.scss";
import DeviceContext from "src/contexts/DeviceContext";
import { fetchAudibleConditionsOfUse } from "src/utils/asinMetadataUtils";
import Spinner from "../Spinner/Spinner";
import debug from "src/utils/debugUtils";
import { NativeAPI } from "src/utils/deviceUtils";
import WorkflowContext from "src/contexts/WorkflowContext";

const amazonLinkRegEx = new RegExp("^https?:\\/\\/[^.]*\\.?amazon\\.");

export const AudibleConditionsOfUse: React.FC = () => {
    const deviceContext = useContext(DeviceContext);
    const workflowContext = useContext(WorkflowContext);
    const [hasFetchedMarkup, setHasFetchedMarkup] = useState(false);
    const [locale, setLocale] = useState(deviceContext.locale);
    const [markup, setMarkup] = useState("");

    useEffect(() => {
        if (locale !== deviceContext.locale) {
            setLocale(deviceContext.locale);
            setHasFetchedMarkup(false);
        }
    }, [deviceContext.locale, locale]);

    useEffect(() => {
        if (!hasFetchedMarkup) {
            const localLocale = deviceContext.locale;
            fetchAudibleConditionsOfUse(localLocale)
                .then((data) => {
                    if (localLocale === deviceContext.locale) {
                        setMarkup(data);
                        setHasFetchedMarkup(true);
                    }
            });
        }
    }, [hasFetchedMarkup, deviceContext.locale]);

    const clickHandler = (event: React.UIEvent) => {
        event.stopPropagation();
        event.preventDefault();
        const target = event.target as Element;
        const targetLink = target?.closest('a');
        if (targetLink) {
            debug.log(`Clicked anchor tag with href ${targetLink.href} and content ${targetLink.textContent}`);
            if (targetLink.href.startsWith("/") || amazonLinkRegEx.test(targetLink.href)) {
                NativeAPI.openWebPage(targetLink.href);
            } else {
                debug.log(`Not opening non-amazon link: ${targetLink.href}`);
                workflowContext.infoMessage(targetLink.href);
            }
        }
    };

    const isReady = hasFetchedMarkup;

    return (<>
        {!isReady && (
            <div style={{ padding: "20px" }}>
                <Spinner />
            </div>
        )}
        {isReady && (<>
            <div className={styles.audibleConditionsOfUse} dangerouslySetInnerHTML={{__html: markup}} onClick={clickHandler} />
        </>)}
    </>);
};
